import { yupResolver } from "@hookform/resolvers/yup";
import { ModalFuncProps, ModalProps } from "antd";
import clsx from "clsx";
import { isFunction } from "lodash";
import { ReactNode, useCallback } from "react";
import { DefaultValues, FieldValues, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Merge } from "type-fest";
import * as yup from "yup";

import { MultistepProvider, Step, useMultistep } from "@sol/forms";
import { usePreventRouteChange } from "@sol/routing";
import Modal from "@sol/uikit/feedback/Modal";
import Button from "@sol/uikit/general/Button";

import { ModalFormSteper } from "./ModalFormSteper";

export type ModalFormSteps = Merge<Step, { stepTitle?: string }>;

type ModalFormProps<TFormValues extends FieldValues = FieldValues> = ModalProps & {
    steps: ModalFormSteps[];
    schema?: yup.ObjectSchema<any>;
    defaultValues?: DefaultValues<TFormValues>;
    children?: ReactNode | ((props: { currentStep: number }) => ReactNode);
    onSuccess?: () => void;
    onError?: () => void;
    onSubmit: (values: TFormValues) => Promise<any>;
};

export const ModalForm = <TFormValues extends Record<string, any>>({
    width = "80%",
    closable = true,
    footer = null,
    schema,
    steps,
    defaultValues,
    children,
    onSubmit,
    onCancel,
    onSuccess,
    onError,
    ...props
}: ModalFormProps<TFormValues>) => {
    const [t] = useTranslation(undefined, { keyPrefix: "@sol" });
    const [modal, modalContextHolder] = Modal.useModal();

    const form = useForm<TFormValues>({
        mode: "onChange",
        ...(schema && { resolver: yupResolver(schema) }),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = form;

    const isDirty = Object.keys(form.formState.dirtyFields).length > 0;

    const multistep = useMultistep({ steps, form, validateOnMount: false });
    const isSingleStep = steps.length === 1;
    const currentStep = steps[multistep.stepIndex];
    const { force: allowRouteChange } = usePreventRouteChange(isDirty);

    const confirmModal = ({ ...props }: ModalFuncProps) => {
        modal.confirm({
            okText: t("forms.ModalForm.warning.actions.confirm"),
            cancelText: t("forms.ModalForm.warning.actions.cancel"),
            centered: true,
            ...props,
        });
    };

    const handleFormSubmit = useCallback(
        async (values: TFormValues) => {
            allowRouteChange();
            const results = await onSubmit(values);

            if (!results) {
                onError?.();
                return;
            }

            form.reset(defaultValues);

            onSuccess?.();

            return results;
        },
        [allowRouteChange, onSubmit, onSuccess, form],
    );

    // Handle modal close with confirmation if there are unsaved changes
    const handleModalCancel = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (isDirty) {
                confirmModal({
                    title: t("forms.ModalForm.warning.unsavedChanges.title"),
                    content: t("forms.ModalForm.warning.unsavedChanges.message"),
                    onOk: () => {
                        allowRouteChange();
                        form.reset(defaultValues);
                        multistep.jump(0);
                        onCancel?.(e);
                    },
                });
                return;
            }

            form.reset(defaultValues);
            onCancel?.(e);
        },
        [isDirty, onCancel, allowRouteChange, t],
    );

    const renderModalWrapper = (children: ReactNode, stepTitle?: string) => (
        <>
            <Modal
                footer={footer}
                width={width}
                closable={closable}
                onCancel={handleModalCancel}
                centered
                title={stepTitle ?? props.title}
                destroyOnClose
                {...props}
            >
                {children}
            </Modal>
            {modalContextHolder}
        </>
    );

    if (!children && steps.length === 0) {
        throw new Error("No form configuration provided");
    }

    return renderModalWrapper(
        <FormProvider {...form}>
            <MultistepProvider {...multistep}>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    {isFunction(children) ? children({ currentStep: multistep.stepIndex }) : children}
                    <div className="flex justify-between">
                        {!isSingleStep && !multistep.isFirstStep && (
                            <Button size="large" onClick={() => multistep.jump(multistep.prev)}>
                                {t("forms.ModalForm.control.prev")}
                            </Button>
                        )}
                        <div
                            className={clsx("ml-auto mt-6 flex items-center justify-center gap-6", {
                                "ml-auto": !isSingleStep,
                            })}
                        >
                            {!isSingleStep && (
                                <ModalFormSteper
                                    stepLabel={currentStep.label}
                                    currentStep={multistep.stepIndex}
                                    totalSteps={steps.length}
                                />
                            )}
                            {!isSingleStep && !multistep.isLastStep ? (
                                <Button
                                    type="primary"
                                    loading={isSubmitting}
                                    onClick={e => {
                                        e.preventDefault();
                                        multistep.jump(multistep.next);
                                    }}
                                    htmlType="button"
                                    size="large"
                                    disabled={!multistep.step.valid}
                                >
                                    {t("forms.ModalForm.control.next")}
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    loading={isSubmitting}
                                    htmlType="submit"
                                    size="large"
                                    disabled={!multistep.step.valid}
                                >
                                    {t("forms.ModalForm.control.submit")}
                                </Button>
                            )}
                        </div>
                    </div>
                </form>
            </MultistepProvider>
        </FormProvider>,
        isSingleStep ? currentStep.label : currentStep.stepTitle,
    );
};
