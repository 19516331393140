import { useObjectRef } from "@react-aria/utils";
import { ReactNode, forwardRef, ForwardRefRenderFunction } from "react";
import { useTextField } from "react-aria";
import { UseControllerProps } from "react-hook-form";
import styled from "styled-components";

import { TextArea } from "@sol/uikit";
import Alert from "@sol/uikit/feedback/Alert";
import Label from "@sol/uikit/inputs/Label";

import { useFormSchema } from "../useFormSchema";
import { BaseFieldProps, useFieldInfo } from "./useFieldInfo";

type Props = {
    hint?: ReactNode;
    placeholder?: string;
    className?: string;
} & BaseFieldProps &
    UseControllerProps;

const TextAreaField: ForwardRefRenderFunction<HTMLTextAreaElement, Props> = (props, forwardedRef) => {
    const ref = useObjectRef(forwardedRef);
    const schema = useFormSchema();

    const fieldInfo = useFieldInfo(props, schema);
    const { label, hint, isRequired, isLoading, errorMessage, max, value } = fieldInfo;

    const { inputProps, labelProps, errorMessageProps } = useTextField<"textarea">(fieldInfo, ref);

    return (
        <div {...props}>
            {label ? (
                <Label {...labelProps} isRequired={isRequired} hint={hint} max={max} value={value}>
                    {label}
                </Label>
            ) : null}
            <TextArea
                minRows={4}
                maxRows={8}
                {...inputProps}
                style={{
                    ...inputProps.style,
                    height: undefined,
                }}
                variant={errorMessage ? "error" : undefined}
                aria-invalid={`${!!errorMessage}`}
                isLoading={isLoading}
            />
            {errorMessage ? (
                <Alert {...errorMessageProps} size="small" intent="error">
                    {errorMessage}
                </Alert>
            ) : null}
        </div>
    );
};

export default styled(forwardRef(TextAreaField))`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[3]};
`;
