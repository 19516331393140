import { cx } from "class-variance-authority";
import { useState } from "react";
import { AriaTextFieldOptions, useObjectRef, useTextField } from "react-aria";
import { UseControllerProps } from "react-hook-form";

import { BaseFieldProps, useFieldInfo, useFormSchema } from "@sol/forms";
import { TextInput } from "@sol/uikit";
import { EyeOff, EyeOn } from "@sol/uikit/core/icons";
import Alert from "@sol/uikit/feedback/Alert";
import Label from "@sol/uikit/inputs/Label";
import { IconButton } from "@sol/uikit/molecules/IconButton";

type Props = {
    className?: string;
} & BaseFieldProps &
    UseControllerProps &
    AriaTextFieldOptions<"input">;

export const PasswordField = ({ className, ...props }: Props) => {
    const schema = useFormSchema();

    const [showPassword, setShowPassword] = useState(false);

    const fieldInfo = useFieldInfo(props, schema);

    const { label, hint, isRequired, isLoading, errorMessage, max, value = "", ref: fieldRef } = fieldInfo;
    const ref = useObjectRef(fieldRef);
    const { inputProps, labelProps, errorMessageProps } = useTextField(
        { ...fieldInfo, ...props, value: value ?? "" },
        ref,
    );

    return (
        <div className={cx(["flex flex-col gap-2", className])}>
            {label ? (
                <Label {...labelProps} isRequired={isRequired} hint={hint} max={max} value={value}>
                    {label}
                </Label>
            ) : null}
            <div className="flex gap-2">
                <TextInput
                    ref={fieldRef}
                    {...inputProps}
                    type={showPassword ? "text" : "password"}
                    variant={errorMessage ? "error" : undefined}
                    aria-invalid={`${!!errorMessage}`}
                    isLoading={isLoading}
                />
                <IconButton
                    label="révéler le mot de passe"
                    variant="secondary"
                    onPress={() => setShowPassword(!showPassword)}
                    className="group bg-grey-lightest text-purple-base hover:text-white-base"
                >
                    {showPassword ? (
                        <EyeOff className="fill-transparent-base group-hover:!fill-purple-base" size={48} />
                    ) : (
                        <EyeOn className="fill-transparent-base group-hover:!fill-purple-base" size={48} />
                    )}
                </IconButton>
            </div>
            {errorMessage ? (
                <Alert {...errorMessageProps} size="small" intent="error">
                    {errorMessage}
                </Alert>
            ) : null}
        </div>
    );
};
