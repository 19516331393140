import { get, isString } from "lodash";
import { ReactNode } from "react";
import { useController } from "react-hook-form";
import * as yup from "yup";

function isSchemaDescription(description?: yup.SchemaFieldDescription): description is yup.SchemaDescription {
    if (description?.hasOwnProperty("nullable")) {
        return true;
    }

    return false;
}

const describe = (schema?: yup.Schema, path?: string) => {
    if (!schema) {
        return;
    }

    const schemaToDescribe = path ? yup.reach(schema, path) : schema;

    if (!yup.isSchema(schemaToDescribe)) {
        return;
    }

    const description = schemaToDescribe.describe();

    return isSchemaDescription(description) ? description : undefined;
};

const descriptionMeta = yup
    .object({
        nullable: yup.boolean().required(),
        optional: yup.boolean().required(),
    })
    .required();

export type BaseFieldProps = {
    name: string;
    label?: ReactNode;
    hint?: ReactNode;
    placeholder?: string;
    isLoading?: boolean;
    noBlur?: boolean;
};

type TError = { message: string };

type ErrorObject<T extends TError = TError> = T | { [Key: string]: ErrorObject<T> };

export const useFieldInfo = ({ name, isLoading, noBlur, ...props }: BaseFieldProps, schema: yup.Schema) => {
    const {
        field,
        fieldState: { invalid, error, isTouched },
        formState: { isSubmitting, isLoading: isFormLoading, defaultValues },
    } = useController({ name: name });

    const description = describe(schema, field.name);
    const defaultValue = get(defaultValues, field.name);

    if (!description) {
        throw new Error("Cannot retrieve field schema description");
    }

    let max: number | Date | undefined = undefined;

    if (description.type === "array" || description.type === "string") {
        const maxRule = description.tests.find(rule => rule.name === "max");
        max = maxRule?.params?.max as number;
    }

    const desc = descriptionMeta.validateSync(description, { stripUnknown: true });

    const isRequired = !(desc.nullable || desc.optional);
    const isInvalid = (noBlur || isTouched) && invalid;

    const getErrorMessage = (err: ErrorObject): string => {
        if (isString(err.message)) {
            return err.message;
        }

        const firstKey = Object.keys(err)[0] as keyof typeof err;

        return getErrorMessage(err[firstKey]);
    };

    const errorMessage = isInvalid && error && getErrorMessage(error as ErrorObject);

    return {
        ...props,
        ...field,
        isRequired,
        isReadOnly: isSubmitting || isFormLoading || isLoading,
        isLoading: isFormLoading || isLoading,
        errorMessage,
        defaultValue,
        max,
    };
};
