import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

import Button from "./MutistepFormNavigationButton";
import { UseMultistepReturn } from "./useMultistep";

type Props = {
    className?: string;
    multistep: UseMultistepReturn;
    isSubmitting?: boolean;
} & ComponentPropsWithoutRef<"nav">;

function MutltistepFormNavigation({ className, multistep, isSubmitting }: Props) {
    const { steps, stepIndex: currentStepIndex, jump } = multistep;

    return (
        <nav className={className}>
            <ul>
                {steps.map((step, index) => {
                    const isCurrent = currentStepIndex === index;

                    return (
                        <li key={index} aria-current={isCurrent ? "step" : "false"}>
                            <Button
                                disabled={isSubmitting || !step.reachable}
                                isCurrent={isCurrent}
                                isVisited={step.visited}
                                index={index}
                                isValid={step.valid}
                                onClick={() => jump(index)}
                            >
                                {step.label}
                            </Button>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
}

export default styled(MutltistepFormNavigation)`
    --dots-margin: calc(
        ${({ theme }) => theme.spacing[4] /* Button padding */} +
            (${({ theme }) => theme.spacing[7] /* Icon width */} / 2)
    );
    --dots-size: 4px;
    padding-left: var(--dots-margin);

    > ul {
        display: flex;
        flex-direction: column;
        gap: ${({ theme }) => theme.spacing[7]};
        margin: 0;
        list-style: none;
        padding: 0;

        border-left: var(--dots-size) dotted ${({ theme }) => theme.palette.grey.lighter};

        > li {
            margin-left: calc(-1 * var(--dots-margin) + -1 * var(--dots-size));
        }
    }
`;
