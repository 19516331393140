import React, { ComponentPropsWithoutRef } from "react";
import styled, { css } from "styled-components";

import { Check, Cross, SvgIcon } from "@sol/icons";
import { focusRingCSS } from "@sol/uikit/utils/focus";

const StepIcon = styled.span`
    border: ${({ theme }) => theme.border.small} solid var(--icon-border-color, var(--icon-bg-color));
    color: var(--icon-color);
    border-radius: 9999px;
    background: var(--icon-bg-color);
    height: 32px;
    width: 32px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ theme }) => theme.typography.p}
    --text-color: var(--icon-color);
    white-space: pre;
    text-align: left;

    > ${SvgIcon} {
        --icon-bg-color: transparent;
    }
`;

type Props = {
    isCurrent: boolean;
    isValid: boolean;
    isVisited: boolean;
    index: number;
} & ComponentPropsWithoutRef<"button">;

function MultistepFormNavigtionButton({
    className,
    children,
    isCurrent,
    isVisited,
    isValid,
    index,
    disabled,
    ...rest
}: Props) {
    const icon = disabled || !isVisited ? index + 1 : isValid ? <Check size={32} /> : <Cross size={32} />;

    return (
        <button className={className} disabled={disabled} {...rest}>
            <StepIcon>{icon}</StepIcon>
            {children}
        </button>
    );
}

export default styled(MultistepFormNavigtionButton)`
    --text-color: ${({ theme, isCurrent, isValid, isVisited, disabled }) => {
        if (disabled) {
            return theme.palette.grey.base;
        }
        if (isCurrent) {
            return theme.palette.black.base;
        }

        if (isVisited) {
            return isValid ? theme.palette.green.base : theme.palette.red.base;
        }

        return theme.palette.grey.base;
    }};
    --icon-color: ${({ theme, isVisited, isValid, disabled }) => {
        if (disabled) {
            return theme.palette.grey.base;
        }

        if (isVisited) {
            return isValid ? theme.palette.green.base : theme.palette.red.base;
        }

        return theme.palette.grey.base;
    }};
    --icon-bg-color: ${({ theme }) => theme.palette.grey.lightest};
    ${({ isVisited, disabled }) => {
        if (isVisited && !disabled) {
            return css`
                --icon-border-color: var(--icon-color);
            `;
        }
    }}
    --btn-side-color: ${({ theme }) => theme.palette.purple.lightest};
    --focus-ring-color: ${({ theme, isVisited, isValid }) => {
        if (isVisited) {
            return isValid ? theme.palette.green.light : theme.palette.red.light;
        }

        return theme.palette.purple.light;
    }};

    transition: all 0.2s;

    ${({ theme }) => theme.typography.label};
    text-align: left;

    width: 100%;
    border: 2px solid ${({ theme }) => theme.palette.grey.lightest};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    padding: ${({ theme }) => theme.spacing[4]};

    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};

    background: ${({ theme }) => theme.palette.white.base};

    --btn-opposite-push-depth: calc(-1 * var(--btn-push-depth));
    transform: translate(var(--btn-opposite-push-depth), var(--btn-opposite-push-depth));

    --btn-side-shadow: var(--btn-push-depth, 0px) var(--btn-push-depth, 0px) 0px var(--btn-side-color);

    :hover:not(:active):not(:disabled),
    :focus:not(:active):not(:disabled) {
        --btn-push-depth: 4px;
        border-color: ${({ theme }) => theme.palette.grey.lighter};
    }

    :active,
    :disabled {
        --btn-push-depth: 0px;
    }

    &:not(:disabled) {
        cursor: pointer;
    }

    &:focus {
        --focus-ring-active: 1;
    }

    ${focusRingCSS}
    box-shadow: var(--focus-ring-shadow), var(--btn-side-shadow);
    outline: none;
`;
