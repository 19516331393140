import { Typography } from "antd";
import clsx from "clsx";

type ModalFormSteperProps = {
    currentStep: number;
    totalSteps: number;
    stepLabel: string;
};

const { Text } = Typography;

export const ModalFormSteper = ({ currentStep, totalSteps, stepLabel }: ModalFormSteperProps) => {
    return (
        <div className="flex items-center gap-4">
            <Text>{stepLabel}</Text>
            <ul className="flex items-center justify-center gap-2">
                {Array.from({ length: totalSteps }).map((_, index) => (
                    <li key={index}>
                        <div
                            className={clsx(
                                "h-2 w-8 rounded-md ",
                                currentStep === index || currentStep > index
                                    ? "bg-primary"
                                    : "bg-[var(--ant-color-primary-bg-hover)]",
                            )}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};
