import { ReactNode, createContext, useContext } from "react";
import * as yup from "yup";

type FormSchemaContext<S extends yup.Schema = yup.Schema> = S;

const context = createContext<FormSchemaContext | null>(null);
context.displayName = "FormSchema";

const { Provider } = context;

export function FormSchemaProvider<TSchema extends yup.Schema = yup.Schema>({
    schema,
    children,
}: {
    schema: TSchema;
    children: ReactNode;
}) {
    return <Provider value={schema}>{children}</Provider>;
}

export function useFormSchema() {
    const value = useContext(context);

    if (!value) {
        throw new Error(`This hook must be use within ${context.displayName} context`);
    }

    return value;
}
