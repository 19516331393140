import React, { ComponentPropsWithoutRef, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useScroll from "src/hooks/useScroll";
import { Text } from "src/uikit";
import styled from "styled-components";

import { Arrow } from "@sol/icons";
import { Button, Container, Grid, GridColumn } from "@sol/uikit";

import MutltistepFormNavigation from "./MutltistepFormNavigation";
import { useMultistepContext } from "./useMultistep";

const NavigationTitle = styled(Text).attrs({ variant: "subheading" })`
    --text-color: ${({ theme }) => theme.palette.grey.light};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

type Props = {
    className?: string;
    children: ReactNode;
    actions?: ReactNode;
    isSubmitting?: boolean;
} & ComponentPropsWithoutRef<typeof Grid>;

function MultistepFormLayout({ className, children, actions, isSubmitting }: Props) {
    const [t] = useTranslation();

    const multistep = useMultistepContext();

    if (!multistep) {
        throw new Error("MultistepFormLayout should be use in the subtree of a MutltistepProvider");
    }

    const { next, prev, isFirstStep, isLastStep, jump, stepIndex } = multistep;

    const scroll = useScroll();

    useEffect(() => {
        if (scroll) {
            scroll.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [stepIndex]);

    return (
        <Grid className={className}>
            <GridColumn col={9}>
                <Grid>
                    <GridColumn col={12} as="section">
                        {children}
                    </GridColumn>
                    <GridColumn col={12} style={{ display: "flex" }}>
                        {!isFirstStep ? (
                            <Button variant="primary" onClick={() => jump(prev)} disabled={isSubmitting || prev === -1}>
                                <Arrow direction="left" />
                                {t("@sol.forms.MultistepFormLayout.prev")}
                            </Button>
                        ) : null}
                        <div style={{ flex: 1 }} />
                        {!isLastStep ? (
                            <Button
                                onClick={() => jump(next)}
                                disabled={isSubmitting || next === -1}
                                variant="primary"
                                filled
                            >
                                {t("@sol.forms.MultistepFormLayout.next")}
                                <Arrow direction="right" />
                            </Button>
                        ) : null}
                    </GridColumn>
                </Grid>
            </GridColumn>
            <GridColumn col={3} as={Container} block>
                <Grid>
                    <GridColumn col={12}>
                        <NavigationTitle>{t("@sol.forms.MultistepFormLayout.navigation.title")}</NavigationTitle>
                        <MutltistepFormNavigation multistep={multistep} isSubmitting={isSubmitting} />
                    </GridColumn>
                    {actions && <GridColumn col={12}>{actions}</GridColumn>}
                </Grid>
            </GridColumn>
        </Grid>
    );
}

export default styled(MultistepFormLayout)`
    > ${GridColumn}:last-of-type {
        align-self: start;
    }
`;
