import { DateValue } from "@internationalized/date";
import { useDateRangePicker } from "@react-aria/datepicker";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { DateRangePickerStateOptions, useDateRangePickerState } from "react-stately";
import styled from "styled-components";

import DateInput from "./DateInput";
import Label from "./Label";
import RangeCalendar from "../Calendar/RangeCalendar";

type Props = { isLoading?: boolean; className?: string } & DateRangePickerStateOptions<DateValue>;

const Group = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing[5]};
`;

const InputGroup = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
`;

function DateRangePicker({ isLoading, className, ...props }: Props) {
    const [t] = useTranslation();
    const state = useDateRangePickerState(props);
    const ref = useRef<HTMLDivElement | null>(null);
    const { groupProps, startFieldProps, endFieldProps, calendarProps } = useDateRangePicker(props, state, ref);

    return (
        <div className={className}>
            <Group {...groupProps} ref={ref}>
                <InputGroup>
                    <Label small hint={t("@sol.uikit.inputs.DateRangePicker.hint", { format: "dd/mm/aaaa" })}>
                        {t("@sol.uikit.inputs.DateRangePicker.start")}
                    </Label>
                    <DateInput {...startFieldProps} isLoading={isLoading} />
                </InputGroup>
                <InputGroup>
                    <Label small hint={t("@sol.uikit.inputs.DateRangePicker.hint", { format: "dd/mm/aaaa" })}>
                        {t("@sol.uikit.inputs.DateRangePicker.end")}
                    </Label>
                    <DateInput {...endFieldProps} isLoading={isLoading} />
                </InputGroup>
            </Group>
            <RangeCalendar {...calendarProps} visibleMonths={3} />
        </div>
    );
}

export default styled(DateRangePicker)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[5]};
`;
