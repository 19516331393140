import { ComponentPropsWithoutRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const NecessityIndicator = styled.span`
    color: ${({ theme }) => theme.palette.red.base};
`;

const Hint = styled.span<{ small?: boolean }>`
    ${({ theme, small }) => !small && theme.typography.p}
    color: ${({ theme }) => theme.palette.grey.base};
`;

type Props = {
    isRequired?: boolean;
    small?: boolean;
    hint?: ReactNode;
    max?: number;
    value?: string;
} & ComponentPropsWithoutRef<"label">;

function Label({ isRequired, small, hint, max, value, children, ...props }: Props) {
    const [t] = useTranslation();

    return (
        <label {...props}>
            {children}
            {isRequired ? <NecessityIndicator>*</NecessityIndicator> : null}
            {hint && (
                <Hint small={small}>
                    {" - "}
                    {hint}
                </Hint>
            )}
            {max && (
                <Hint small={small}>
                    {" - "}
                    {`${value ? value.length : 0} / ${t("component.shared.Label.max-characters", { max })}`}
                </Hint>
            )}
        </label>
    );
}

export default styled(Label)`
    ${({ theme, small }) => (small ? theme.typography["subheading"] : theme.typography.label)};
`;
