export type StepState = {
    valid: boolean; // Passes validation
    enabled: boolean; // If not enabled the step will be skipped
    optional: boolean; // The step can be skipped in the aside
    visited: boolean; // If the step has been visited
};

/**
 * Returns if the specified step is reachable or not with the following rules
 * - We cannot go to steps after the highest visited step if one of the visited steps is invalid
 * - We cannot go to disabled steps
 * - We cannot go more than the first non-optional enabled step after the highest visited one
 */
export const isStepReachable = (data: {
    step: number; // The step we want to check
    stepsStates: StepState[]; // the steps state array
    currentStep: number; // the step we are in
}): boolean => {
    const { step, stepsStates, currentStep } = data;

    // The step in the past it's always reachable
    if (step < currentStep) {
        return stepsStates[step].enabled;
    }

    // Rule 1: We cannot go to steps after the highest visited step if one of the visited steps is invalid
    const highestVisitedStep = stepsStates.reduce(
        (highestStep, state, index) => (state.visited ? index : highestStep),
        -1,
    );

    if (highestVisitedStep >= 0) {
        const hasInvalidStep = stepsStates
            .slice(0, highestVisitedStep + 1)
            .some((state, index) => index !== step && !state.valid);

        if (hasInvalidStep) {
            return false;
        }
    }

    // Rule 2: We cannot go to disabled steps
    if (!stepsStates[step].enabled) {
        return false;
    }

    //Rule 3: We cannot go more than the first no optional enabled step after the highest visited one
    const firstNonOptionalEnabledStep = stepsStates.findIndex(
        (state, index) => index > highestVisitedStep && state.enabled && !state.optional,
    );

    if (firstNonOptionalEnabledStep >= 0 && step > firstNonOptionalEnabledStep) {
        return false;
    }

    // The step is reachable if it passes all the rules
    return true;
};
