import { isSameDay, getDayOfWeek, isSameMonth, CalendarDate } from "@internationalized/date";
import clsx from "clsx";
import { ComponentPropsWithoutRef, useRef } from "react";
import { useCalendarCell, useLocale, AriaCalendarCellProps } from "react-aria";
import { CalendarState, RangeCalendarState } from "react-stately";
import styled, { css } from "styled-components";

type Props = {
    state: CalendarState | RangeCalendarState;
    currentMonth: CalendarDate;
} & AriaCalendarCellProps &
    ComponentPropsWithoutRef<"td">;

const Button = styled.button`
    height: 100%;
    width: 100%;

    border: 0;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: var(--btn-border-radius);

    background: var(--btn-background-color, transparent);

    ${({ theme }) => theme.typography.p}

    --focus-ring-color: ${({ theme }) => theme.palette.yellow.base};

    &:hover:not(:disabled) {
        cursor: pointer;
        background: ${({ theme }) => theme.palette.white.base};
        color: ${({ theme }) => theme.palette.purple.base};
        border: 3px solid ${({ theme }) => theme.palette.purple.base};
        z-index: ${({ theme }) => theme.zIndex.element + 6};
    }

    &:focus {
        outline: 3px solid var(--focus-ring-color);
    }

    :disabled {
        color: ${({ theme }) => theme.palette.grey.lighter};
    }
`;

function CalendarCell({ state, currentMonth, date, isDisabled: isCellDisabled, ...props }: Props) {
    const ref = useRef<HTMLButtonElement | null>(null);

    const { cellProps, buttonProps, isSelected, isDisabled, formattedDate } = useCalendarCell(
        { date, isDisabled: isCellDisabled },
        state,
        ref,
    );

    const isOutsideMonth = !isSameMonth(currentMonth, date);

    // The start and end date of the selected range will have
    // an emphasized appearance.
    let isSelectionStart = isSelected;
    let isSelectionEnd = isSelected;
    if ("highlightedRange" in state && state.highlightedRange) {
        const {
            highlightedRange: { start, end },
        } = state;

        isSelectionStart = isSameDay(date, start);
        isSelectionEnd = isSameDay(date, end);
    }

    // We add rounded corners on the left for the first day of the month,
    // the first day of each week, and the start date of the selection.
    // We add rounded corners on the right for the last day of the month,
    // the last day of each week, and the end date of the selection.
    const { locale } = useLocale();
    const dayOfWeek = getDayOfWeek(date, locale);
    const isRoundedLeft = isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
    const isRoundedRight =
        isSelected && (isSelectionEnd || dayOfWeek === 6 || date.day === date.calendar.getDaysInMonth(date));
    const isMiddleRange = isSelected && !(isSelectionStart || isSelectionEnd);

    return (
        <td
            {...props}
            {...cellProps}
            data-class={clsx(
                isRoundedLeft && "rounded-left",
                isRoundedRight && "rounded-right",
                isMiddleRange && "middle-range",
            )}
            hidden={isOutsideMonth}
        >
            <Button {...buttonProps} ref={ref} hidden={isOutsideMonth} disabled={isDisabled}>
                {formattedDate}
            </Button>
        </td>
    );
}

export default styled(CalendarCell)`
    position: relative;
    display: block;
    padding: 0;
    height: 32px;
    width: 32px;

    :focus-within {
        z-index: ${({ theme }) => theme.zIndex.element + 5};
    }

    --btn-border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    &[data-class~="rounded-left"] {
        border-top-left-radius: var(--btn-border-radius);
        border-bottom-left-radius: var(--btn-border-radius);
    }
    &[data-class~="rounded-right"] {
        border-top-right-radius: var(--btn-border-radius);
        border-bottom-right-radius: var(--btn-border-radius);
    }

    &[aria-selected="true"] {
        :not([hidden]) {
            ${({ isDisabled, theme }) =>
                !isDisabled &&
                css`
                    background: ${theme.palette.purple.light};
                    --btn-background-color: ${theme.palette.purple.light};
                    --text-color: ${theme.palette.white.base};
                `};
        }

        :not([data-class~="middle-range"]) {
            --btn-background-color: ${({ theme }) => theme.palette.purple.base};
        }
    }

    &[hidden] > * {
        display: none;
    }
`;
