import { ComponentPropsWithoutRef } from "react";
import { Text } from "src/uikit";
import styled, { css } from "styled-components";

import { Warning, SvgIcon } from "@sol/icons";
import { SvgIconProps } from "@sol/icons/types";

type Sizes = "x-small" | "small" | "medium" | "large";

type Intent = "info" | "error" | "success";

type TextProps = ComponentPropsWithoutRef<typeof Text>;

type Props = {
    intent: Intent;
    size: Sizes;
} & TextProps;

const Alert = ({ children, size, intent, ...props }: Props) => {
    let typography: TextProps["variant"] = "label-small";
    let iconSize: SvgIconProps["size"] = 12;

    switch (size) {
        case "x-small":
            break;
        case "small":
            typography = "subheading";
            iconSize = 16;
            break;
        case "medium":
            typography = "label";
            iconSize = 24;
            break;
        case "large":
            typography = "h3";
            iconSize = 32;
            break;
    }

    return (
        <Text variant={typography} {...props}>
            <Warning size={iconSize} circle />
            {children}
        </Text>
    );
};

export default styled(Alert)`
    ${({ theme, intent, size }) => {
        let color = theme.palette.purple.base;
        let bgColor = theme.palette.purple.lighter2;
        let gap = theme.spacing[2];

        switch (intent) {
            case "success":
                color = theme.palette.green.base;
                bgColor = theme.palette.green.base;
                break;
            case "error":
                color = theme.palette.red.base;
                bgColor = theme.palette.red.lightest;
                break;
        }

        switch (size) {
            case "x-small":
                break;
            case "small":
                gap = theme.spacing[3];
                break;
            case "medium":
                gap = theme.spacing[3];
                break;
            case "large":
                gap = theme.spacing[4];
                break;
        }

        const iconColor = intent === "success" ? theme.palette.white.base : color;

        return css`
            --icon-color: ${iconColor};
            --text-color: ${color};
            --icon-bg-color: ${bgColor};

            > ${SvgIcon} {
                display: inline-block;
                vertical-align: bottom;
                margin-right: ${gap};
            }
        `;
    }}
`;
