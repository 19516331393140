import { CalendarDate, DateValue } from "@internationalized/date";
import { omit } from "lodash";
import { ComponentPropsWithoutRef } from "react";
import { UseControllerProps } from "react-hook-form";
import styled from "styled-components";

import Alert from "@sol/uikit/feedback/Alert";
import DateRangePicker from "@sol/uikit/inputs/DateRangePicker";
import Label from "@sol/uikit/inputs/Label";

import { useFormSchema } from "../useFormSchema";
import { BaseFieldProps, useFieldInfo } from "./useFieldInfo";

type Props = {
    className?: string;
} & BaseFieldProps &
    UseControllerProps &
    Partial<Pick<ComponentPropsWithoutRef<typeof DateRangePicker>, "minValue" | "maxValue">>;

const DateRangeField = ({ className, minValue, maxValue, ...props }: Props) => {
    const schema = useFormSchema();

    const fieldInfo = useFieldInfo(props, schema);

    const { label, hint, isRequired, errorMessage, value, onChange } = fieldInfo;

    const labelProps = {};

    const input = (value = new Date()): CalendarDate =>
        new CalendarDate(value.getFullYear(), value.getMonth() + 1, value.getDate());

    return (
        <div className={className} {...props}>
            {label ? (
                <Label {...labelProps} hint={hint} isRequired={isRequired}>
                    {label}
                </Label>
            ) : null}

            <DateRangePicker
                minValue={minValue}
                maxValue={maxValue}
                {...omit(fieldInfo, "ref", "onChange", "value")}
                onChange={value => {
                    const { start, end } = value;
                    const output = (value: DateValue) => {
                        const { day, month, year } = value;
                        const date = new Date(year, month - 1, day);
                        return date;
                    };

                    onChange({
                        start: output(start),
                        end: output(end),
                    });
                }}
                value={value && { start: input(value.start), end: input(value.end) }}
            />
            {errorMessage ? (
                <Alert size="small" intent="error">
                    {errorMessage}
                </Alert>
            ) : null}
        </div>
    );
};

export default styled(DateRangeField)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[3]};
`;
