import { createCalendar } from "@internationalized/date";
import { useRangeCalendar, AriaRangeCalendarProps } from "@react-aria/calendar";
import { useLocale } from "@react-aria/i18n";
import { useRangeCalendarState } from "@react-stately/calendar";
import { useRef } from "react";
import { DateValue } from "react-aria";
import styled from "styled-components";

import CalendarGrid from "./CalendarGrid";
import CalendarHeader from "./CalendarHeader";

const ContentView = styled.div`
    display: flex;
    gap: var(--calendar-gap);
    justify-content: center;
`;

type Props = {
    className?: string;
    /**
     * The number of months to display at once. Up to 3 months are supported.
     * @default 2
     */
    visibleMonths?: number;
} & AriaRangeCalendarProps<DateValue>;

function RangeCalendar({ visibleMonths = 2, className, ...props }: Props) {
    const { locale } = useLocale();

    const state = useRangeCalendarState({
        ...props,
        visibleDuration: { months: visibleMonths },
        locale,
        createCalendar,
    });

    const ref = useRef<HTMLDivElement | null>(null);
    const { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(props, state, ref);

    return (
        <div className={className} {...calendarProps} ref={ref}>
            <CalendarHeader
                state={state}
                aria-label={calendarProps["aria-label"]}
                prevButtonProps={prevButtonProps}
                nextButtonProps={nextButtonProps}
            />
            <ContentView>
                {[...new Array(visibleMonths).keys()].map(month => {
                    return <CalendarGrid key={month} state={state} offset={{ months: month }} />;
                })}
            </ContentView>
        </div>
    );
}

export default styled(RangeCalendar)`
    --calendar-gap: ${({ theme }) => theme.spacing[8]};

    display: flex;
    flex-direction: column;
    justify-content: center;
`;
