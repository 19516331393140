import { getWeeksInMonth, endOfMonth, DateDuration } from "@internationalized/date";
import { ComponentPropsWithoutRef } from "react";
import { useCalendarGrid, useLocale, useDateFormatter } from "react-aria";
import { CalendarState, RangeCalendarState } from "react-stately";
import { Text } from "src/uikit";
import styled from "styled-components";

import CalendarCell from "./CalendarCell";

type Props = {
    state: CalendarState | RangeCalendarState;
    offset?: DateDuration;
} & ComponentPropsWithoutRef<"table">;

function CalendarGrid({ state, offset = {}, ...props }: Props) {
    const { locale } = useLocale();
    const startDate = state.visibleRange.start.add(offset);
    const endDate = endOfMonth(startDate);
    const { gridProps, headerProps, weekDays } = useCalendarGrid(
        {
            startDate,
            endDate,
        },
        state,
    );

    // Get the number of weeks in the month so we can render the proper number of rows.
    const weeksInMonth = getWeeksInMonth(startDate, locale);

    const monthDateFormatter = useDateFormatter({
        month: "long",
        year: "numeric",
        timeZone: state.timeZone,
    });

    return (
        <div {...props}>
            <Text as="h2" aria-hidden variant="h4" textAlign="center">
                {monthDateFormatter.format(startDate.toDate(state.timeZone))}
            </Text>
            <table {...gridProps} cellPadding="0">
                <thead {...headerProps} className="text-gray-600">
                    <tr>
                        {weekDays.map((day, index) => (
                            <th key={index}>{day}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {[...new Array(weeksInMonth).keys()].map(weekIndex => (
                        <tr key={weekIndex}>
                            {state
                                .getDatesInWeek(weekIndex, startDate)
                                .map((date, i) =>
                                    date ? (
                                        <CalendarCell key={i} state={state} date={date} currentMonth={startDate} />
                                    ) : (
                                        <td key={i} />
                                    ),
                                )}
                        </tr>
                    ))}
                    {/* Add an extra row to avoid layouts shifts */}
                    {weeksInMonth < 6 ? (
                        <tr>
                            <td colSpan={7} />
                        </tr>
                    ) : null}
                </tbody>
            </table>
        </div>
    );
}

export default styled(CalendarGrid)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[1]};

    tbody,
    thead {
        display: flex;
        flex-direction: column;
        gap: ${({ theme }) => theme.spacing[3]};
    }

    tr {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    th {
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        ${({ theme }) => theme.typography.label}
    }

    td {
        height: 32px;
    }
`;
