import { get } from "lodash";
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

import { isStepReachable } from "./multistepNavigation";

export type Step = {
    label: string;
    validate: string[];
    optional?: boolean;
    enabled?: boolean;
};

type UseMultistepProps<TFieldValues extends FieldValues = FieldValues, TContext = any> = {
    steps: Array<Step>;
    form: UseFormReturn<TFieldValues, TContext>;
    defaultStep?: number;
    defaultMaxVisited?: number;
    validateOnMount?: boolean;
};

export function useMultistep<TFieldValues extends FieldValues = FieldValues, TContext = any>(
    {
        steps,
        form,
        defaultMaxVisited = 0,
        defaultStep = 0,
        validateOnMount = true,
    }: UseMultistepProps<TFieldValues, TContext>,
    deps: any[] = [],
) {
    const [state, setState] = useState({
        current: defaultStep,
        maxVisited: Math.max(0, defaultStep, defaultMaxVisited),
    });

    const { current: currentStep, maxVisited } = state;

    const {
        formState: { errors },
        trigger,
        register,
        watch,
    } = form;

    steps.forEach(({ validate = [] }) => {
        validate.forEach(name => register(name as Path<TFieldValues>));
    });

    useEffect(() => {
        if (validateOnMount) {
            trigger();
        }
    }, [validateOnMount]);

    const formValues = watch();

    const stepsWithState = useMemo(() => {
        return steps
            .map(({ validate = [], ...step }, index) => {
                const hasError = (name: string) => {
                    return !!get(errors, name);
                };

                const hasValue = (name: string) => {
                    const value = get(formValues, name);

                    // Handle common empty cases in one check
                    if (value === undefined || value === null || value === "") {
                        return false;
                    }

                    // For arrays, check if they have elements
                    if (Array.isArray(value)) {
                        return value.length > 0;
                    }

                    // For objects, check if they have properties
                    if (typeof value === "object") {
                        return Object.keys(value).length > 0;
                    }

                    // For all other types, consider them as having a value
                    return true;
                };

                const stepState = {
                    valid:
                        validate.length === 0
                            ? true
                            : validate.every(field => {
                                  // If the field has an error, it's not valid
                                  if (hasError(field)) {
                                      return false;
                                  }

                                  // If the step is optional, we don't need to check if fields have values
                                  if (step.optional) {
                                      return true;
                                  }

                                  // For non-optional steps, fields must have values
                                  return hasValue(field);
                              }),
                    optional: step.optional ?? false,
                    visited: maxVisited >= index,
                    enabled: step.enabled ?? true,
                };

                return {
                    ...step,
                    validate,
                    ...stepState,
                };
            })
            .map((stepWithState, stepIndex, steps) => ({
                ...stepWithState,
                reachable: isStepReachable({ step: stepIndex, stepsStates: steps, currentStep }),
            }));
    }, [JSON.stringify(errors), maxVisited, currentStep, JSON.stringify(formValues), validateOnMount, ...deps]);

    const canJump = useCallback(
        (step: number) => step >= 0 && step < stepsWithState.length && stepsWithState[step].reachable,
        [stepsWithState],
    );

    const jump = useCallback(
        (step: number) => {
            if (canJump(step)) {
                setState(state => ({ maxVisited: Math.max(state.maxVisited, step), current: step }));
            }
        },
        [canJump],
    );

    const next = useMemo(() => {
        return stepsWithState.findIndex((step, index) => index > currentStep && step.reachable);
    }, [stepsWithState, currentStep]);

    const prev = useMemo(() => {
        return stepsWithState.findLastIndex((step, index) => index < currentStep && step.reachable);
    }, [stepsWithState, currentStep]);

    return useMemo(
        () => ({
            steps: stepsWithState,
            stepIndex: currentStep,
            step: stepsWithState[currentStep],
            maxStepVisited: maxVisited,
            jump,
            next,
            prev,
            isFirstStep: currentStep === 0,
            isLastStep: currentStep === stepsWithState.length - 1,
        }),
        [jump, next, prev, maxVisited, currentStep, stepsWithState],
    );
}

export type UseMultistepReturn<TFieldValues extends FieldValues = FieldValues, TContext = any> = ReturnType<
    typeof useMultistep<TFieldValues, TContext>
>;

const context = createContext<UseMultistepReturn | null>(null);

export const useMultistepContext = () => useContext(context);

export function MultistepProvider<TFieldValues extends FieldValues = FieldValues>({
    children,
    ...props
}: { children: ReactNode } & UseMultistepReturn<TFieldValues>) {
    return <context.Provider value={props}>{children}</context.Provider>;
}

export default useMultistep;
