import { ComponentPropsWithoutRef } from "react";
import { VisuallyHidden } from "react-aria";
import { CalendarState, RangeCalendarState } from "react-stately";
import { Text } from "src/uikit";
import styled from "styled-components";

import { Chevron } from "@sol/icons";

import { IconButton } from "../buttons";

type Props = {
    state: CalendarState | RangeCalendarState;
    prevButtonProps: ComponentPropsWithoutRef<typeof IconButton>;
    nextButtonProps: ComponentPropsWithoutRef<typeof IconButton>;
} & ComponentPropsWithoutRef<"div">;

function CalendarHeader({ state, "aria-label": ariaLabel, prevButtonProps, nextButtonProps, ...props }: Props) {
    return (
        <div {...props}>
            {/* Add a screen reader only description of the entire visible range rather than
             * a separate heading above each month grid. This is placed first in the DOM order
             * so that it is the first thing a touch screen reader user encounters.
             * In addition, VoiceOver on iOS does not announce the aria-label of the grid
             * elements, so the aria-label of the Calendar is included here as well. */}
            {ariaLabel && <VisuallyHidden elementType="h2">{ariaLabel}</VisuallyHidden>}
            <IconButton {...prevButtonProps} size="small" onClick={prevButtonProps.onPress}>
                <Chevron direction="left" size={12} />
            </IconButton>
            <IconButton {...nextButtonProps} size="small" onClick={nextButtonProps.onPress}>
                <Chevron direction="right" size={12} />
            </IconButton>
        </div>
    );
}

export default styled(CalendarHeader)`
    display: flex;
    align-items: center;
    position: relative;
    gap: var(--calendar-gap);

    ${IconButton} {
        position: absolute;
        top: -2px;
        &:first-of-type {
            left: 0;
        }
        &:last-of-type {
            right: 0;
        }
    }

    > ${Text} {
        flex: 1;
        text-align: center;
    }
`;
